.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.overlay-content {
  background-color: #fff;
  padding: 40px 30px;
  border-radius: 10px;
  position: relative;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
}

.sign-up-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sign-up-form label {
  text-align: left !important;
  font-size: 0.7em;
  margin-bottom: 10px;
  width: 100%;
}

.sign-up-form input {
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

.button-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.submit-button {
  background-color: #007BFF;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

/* Responsive design for smaller screens */
@media (max-width: 480px) {
  .overlay-content {
    width: 90%;
    padding: 20px;
  }
}

.form-footer {
  display: flex;
  align-items: center;
  text-align: center;
  flex-grow: 1;
}

.switch-form-button {
  background: none;
  border: none;
  color: #007BFF;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;

}

.switch-form-button:hover {
  color: #0056b3;
  text-decoration: underline;

} 