body, .overlay-content, .sign-up-form, .switch-form-button, .submit-button {
  font-family: Arial, sans-serif;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.overlay-content {
  background-color: #fff;
  padding: 40px 30px;
  border-radius: 10px;
  position: relative;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
}

.sign-up-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sign-up-form h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.sign-up-form label {
  text-align: left;
  font-size: 0.9em;
  margin-bottom: 10px;
  width: 100%;
}

.sign-up-form label-right {
  text-align: right;
  font-size: 0.9em;
  margin-bottom: 20px;
  width: 300px;
}

.sign-up-form input {
  padding: 8px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.button-footer-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  paddiing: 0px;
}

.form-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns buttons to the left */
}

.switch-form-button {
  background: none;
  border: none;
  color: #007BFF;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font-size: 14px;
  text-align: left;
}

.switch-form-button:hover {
  text-decoration: none;
}

.submit-button {
  background-color: #007BFF;
  border: none;
  padding: 10px 20px;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

.switch-form-button, .forgot-password-link {
  font-size: 14px;
  display: block;
}

.forgot-password-container, .form-footer {
  margin-left: 0;
  padding-left: 0;
}

.forgot-password-container {
  text-align: right;
  width: 100%;
  font-size: 10px;
} 

.forgot-password-link {
  font-size: 0.9em !important;
  text-align: right !important;
  display: block;
}

/* Add this to your LoginForm.css file */
.form-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns buttons to the left */
}

.switch-form-button,
.submit-button {
  margin-bottom: 10px; /* Adds space between buttons */
}