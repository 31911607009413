.edit-comic {
  padding: 20px;
  width: 1000px;
  margin: 0 auto;
  overflow-x: auto;
}

.edit-comic h2 {
  text-align: center;
  margin-bottom: 20px;
}

.panel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  width: 100%;
}

.panel-image {
  width: 300px;
  height: auto;
  max-height: 360px;
  object-fit: contain;
  display: block;
  background-color: #f9f9f9;
  margin: 0;
}

.panel-text-editor {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
}

.panel-text-editor textarea {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
  margin: 0;
}

button {
  display: inline-block;
  padding: 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #218838;
}

.edit-title-field {
  display: flex;
  align-items: center;
  gap: 10px;
}

.edit-title-field label,
.edit-title-field input {
  font-family: inherit;
  font-size: 16px;
}

.buttons-row {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.buttons-row button {
  min-width: 300px;
  max-width: 400px;
} 