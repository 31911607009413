@font-face {
  font-family: 'ReadyforAnythingBB';
  src: url('../../public/fonts/ReadyforAnythingBB-Regular.ttf') format('truetype');
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  font-family: 'ReadyforAnythingBB', sans-serif;
}

.overlay-content button {
  margin: 5px;
} 