.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.overlay-content {
  background-color: #fff;
  padding: 40px 30px;
  border-radius: 10px;
  position: relative;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.overlay-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.overlay-form label {
  text-align: left;
  font-size: 0.9em;
  margin-bottom: 5px;
  width: 100%;
}

.overlay-form input {
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

.button-footer-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.submit-button {
  background-color: #007BFF;
  color: white;
  padding: 10px;
  margin: 5px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  flex: 1;
  margin-right: 10px;
}

.submit-button:last-child {
  margin-right: 0;
}

.submit-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.success-message {
  color: green;
  margin-bottom: 10px;
}

/* Responsive design for smaller screens */
@media (max-width: 480px) {
  .overlay-content {
    width: 90%;
    padding: 20px;
  }
} 

h2 {
  margin: 0;
  font-size: 24px;
  font-family: 'ReadyforAnythingBB-Regular';
  color: #000000;
  text-align: center;
}

.cancel-button {
  background-color: white;
  color: black;
  border: 1px solid #ccc;
}

.switch-form-button {
  background: none;
  border: none;
  color: #007BFF;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font-size: 14px;
  font-family: sans-serif;
}

.switch-form-button:hover {
  text-decoration: none;
}