@font-face {
  font-family: 'ReadyforAnythingBB-Regular';
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ReadyforAnythingBB-Regular';
  font-weight: bold;
  font-style: italic;
}

.story-form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.error-alert {
  background-color: #fee2e2;
  border: 1px solid #ef4444;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 20px;
  color: #dc2626;
  font-family: 'Arial', sans-serif;
}

.error-alert strong {
  display: block;
  margin-bottom: 4px;
}

.form-group {
  width: 100%;
  margin-bottom: 10px;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  flex-wrap: nowrap;
}

.form-row .form-group {
  flex: 1 1 auto;
  min-width: 0;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #374151;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
}

textarea,
select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
}

input[type="text"] {
  width: 95%;
  padding: 8px 12px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
}

textarea {
  width: 100%;
  padding: 10px 10px 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'ReadyforAnythingBB-Regular', sans-serif;
  font-size: 1rem;
  box-sizing: border-box;
  resize: vertical;
}

select {
  height: 40px;
}

.generate-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  width: 400px !important;
}

.generate-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  margin-top: 20px;
  padding: 15px;
  background-color: #ffe5e5;
  border-left: 5px solid #ff0000;
  color: #a94442;
}

.error-message h2 {
  margin-top: 0;
  margin-bottom: 5px;
}

.error-message p {
  margin: 0;
}

.story-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 10px;
}

.story-form input,
.story-form select {
  width: 100%;
  max-width: 1000px;
  margin-bottom: 10px;
}

.story-form textarea {
  width: 100%;
  margin-bottom: 10px;
}

.generate-button {
  width: 100%;
  max-width: 300px;
  padding: 10px 20px;
  font-size: 1.2em;
}

@media (max-width: 600px) {
  .generate-button {
    max-width: 100%;
    font-size: 1em;
  }

  .story-form input,
  .story-form select,
  .story-form textarea {
    max-width: 100%;
  }
}

.story-idea-textarea {
  width: 100%;
  box-sizing: border-box;
}

.story-form-container h3 {
  font-family: 'ReadyforAnythingBB', sans-serif;
}

.form-row select {
  width: 100%;
}

.see-all-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.see-all-link {
  text-decoration: none;
  color: #000000;
  font-weight: bold;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.form-group textarea {
  font-family: 'ReadyforAnythingBB', sans-serif;
} 