.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
  font-family: 'Arial', sans-serif;
}

.faq-item {
  margin-bottom: 30px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.faq-item h2 {
  color: #2d3748;
  margin-bottom: 15px;
  font-size: 1.4em;
  font-family: 'ReadyforAnythingBB', sans-serif;
  margin-top: 24px;
}

.faq-item p,
.faq-item ul {
  text-align: left;
}

.faq-item p {
  line-height: 1.6;
  margin-bottom: 15px;
  font-family: 'Arial', sans-serif;
}

.faq-item ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 15px;
  font-family: 'Arial', sans-serif;
}

.faq-item li {
  margin-bottom: 8px;
  line-height: 1.4;
}

.faq-container h1 {
  text-align: center;
  margin-bottom: 30px;
  color: #2d3748;
} 