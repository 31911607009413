@font-face {
  font-family: 'ReadyforAnythingBB';
  src: url('../../public/fonts/ReadyforAnythingBB-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.panel {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #505050; /* Darker grey */
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 750px;
  max-height: 900px;
  font-family: 'ReadyforAnythingBB';
} 