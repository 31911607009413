.gallery-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.gallery-item {
  background: white;
  border-radius: 8px;
  cursor: pointer;
}

.gallery-item img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: contain;
}

.image-info {
  padding: 15px;
}

.image-info p {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
}

.image-info p:first-child {
  font-weight: bold;
  font-size: 16px;
}

h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2em;
  color: #333;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 16px;
  margin: 0 10px;
  font-size: 16px;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination span {
  font-size: 18px;
}

.loading {
  text-align: center;
  font-size: 1.5em;
  margin-top: 50px;
}

.like-icon {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
  margin-right: 8px;
}

.like-count {
  font-family: sans-serif;
  font-size: 14px;
  color: #333;
}

.like-container {
  display: flex;
  align-items: center;
}

.poster-name {
  font-family: sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  display: block;
  text-align: left;
  margin-bottom: 4px;
} 