.my-comics-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.my-comics-container h3 {
  margin-top: 0px; /* Adjust this value to reduce space */
}

.comics-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.comic-row {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 10px;
}

.comic-thumbnail {
  cursor: pointer;
  margin-right: 20px;
}

.comic-thumbnail img {
  width: 200px;
  height: auto;
  object-fit: contain;
}

.comic-details {
  flex: 1;
  font-family: sans-serif;
}

.comic-details p {
  margin: 5px 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.comic-details button {
  padding: 4px 16px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 20px;
  display: block;
}

.comic-details label {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.comic-details select {
  padding: 2px 4px;
  font-size: 12px;
  height: auto;
  width: 100px;
}

.my-comics-message {
  text-align: center;
  margin-top: 50px;
  font-size: 1.2em;
  margin-bottom: 300px;
}

.my-comics-message a {
  color: #007BFF;
  text-decoration: none;
}

.my-comics-message a:hover {
  text-decoration: underline;
}

.prompt-textarea {
  width: 100%;
  min-height: 80px;
  resize: vertical;
  margin-top: 5px;
  padding: 5px;
  font-size: 14px;
}

.my-button {
  padding: 5px 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none; /* so Links won't have underlines */
  display: inline-flex;  /* ensures consistent box alignment for links/buttons */
  align-items: center;   /* vertical centering */
  margin-right: 10px;    /* small spacing on the right */
}

.my-button:hover {
  filter: brightness(0.90);
}

.my-button.edit-button {
  background-color: #28a745;
}
/* You can make another .delete-button, etc. */