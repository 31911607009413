@font-face {
  font-family: 'ReadyforAnythingBB';
  src: url('../public/fonts/ReadyforAnythingBB-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.App {
  font-family: 'ReadyforAnythingBB', sans-serif;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  padding: 20px 20px 10px 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-top: 39px;
}

.header-title {
  margin: 0;
  font-size: 2.5em;
}

.nav-account-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: nowrap;
  margin-top: 10px;
}

.header-nav {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: nowrap;
}

.App-link {
  color: #000;
  text-decoration: none;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
}

.App-link:hover {
  color: #333333;
}

@media (max-width: 600px) {
  .nav-account-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .nav-account-container > * {
    margin-bottom: 10px;
  }
}

h1 {
  margin: 0;
  font-size: 2.5em;
  font-family: 'ReadyforAnythingBB', sans-serif;
  color: #000000;
  text-align: center;
}

.App-link {
  color: #000;
  text-decoration: none;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  margin: 0 10px;
  transition: color 0.2s;
}

.App-link:hover {
  color: #333333;
}

.App-link:active {
  color: #666666;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.progress-bar-container {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.progress-bar {
  width: 80%;
  margin: 0 auto;
  background-color: #e0e0e0;
  border-radius: 25px;
  overflow: hidden;
  height: 20px;
  position: relative;
}

.progress-bar-fill {
  width: 0%;
  height: 100%;
  background-color: #3b82f6;
  animation: loadingAnimation 20s linear forwards;
}

@keyframes loadingAnimation {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

main {
  padding: 20px;
}

html {
  scroll-behavior: smooth;
}

.nav-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  margin-bottom: 20px;
}

.nav-links a {
  text-decoration: none;
  color: #2d3748;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.nav-links a:hover {
  background-color: #e2e8f0;
}

.title-font {
  font-family: 'ReadyforAnythingBB', sans-serif;
  font-size: 2rem;
  font-weight: bold;
}

.App-header h1 {
  min-width: 300px;
  margin-right: 00px;
}

/* Bold font for active navigation links */
.App-link-active {
  font-weight: bold;
}

/* Responsive styles for small screens */
@media (max-width: 600px) {
  .App-header {
    flex-direction: column;
  }

  .App-header nav {
    margin-top: 10px;
  }

  .App-header > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Adjust gap as needed */
  }
}

.App-footer {
  background-color: #ffffff;
  padding: 10px 0;
  text-align: center;
  border-top: 1px solid #eaeaea;
  margin-top: 20px;
}

.App-footer .App-link img {
  height: 35px;
  vertical-align: middle;
}

.nav-account-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: nowrap;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .nav-account-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .nav-account-container > * {
    margin-bottom: 10px;
  }
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.nav-account-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.header-nav {
  display: flex;
}

.footer-links {
  margin-top: 20px; /* Adds vertical space above the links */
}

.footer-links .App-link {
  font-size: 0.8em; /* Reduces the font size of the links */
}

.main-content {
  padding-top: 50px; /* Adjust this value if needed to match the height of the notification bar */
}

.main-content.with-notification {
  padding-top: 50px; /* Adjust this value if needed to match the height of the notification bar */
}
