@font-face {
  font-family: 'ReadyforAnythingBB';
  src: url('../../public/fonts/ReadyforAnythingBB-Regular.ttf') format('truetype');
}

.notification-bar {
  font-family: 'ReadyforAnythingBB', sans-serif;
  background-color: #ffcc00;
  color: #333;
  text-align: center;
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.App-header.with-notification {
  margin-top: 50px; /* Adjust this value to match the height of the notification bar */
}

.App-header {
  margin-top: 0; /* Default margin when notification bar is not visible */
} 