/* src/components/AccountMenu.css */
.account-menu {
  position: relative;
  display: inline-block;
}

.user-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-icon-svg {
  width: 100%;
  height: 100%;
  fill: #000000; /* Change the color if needed */
}

.menu-dropdown {
  position: absolute;
  right: 0;
  background-color: white;
  min-width: 150px;
  box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
  padding: 10px;
  z-index: 1;
}

.menu-dropdown a,
.menu-dropdown button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 8px;
  border: none;
  background: none;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  color: #000;
}

.menu-dropdown a:hover {
  text-decoration: none;
}

.menu-dropdown button:hover {
  background-color: #f0f0f0;
}

.menu-dropdown .username {
  font-family: Arial, sans-serif;
  font-size: 14px;
  padding: 8px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}