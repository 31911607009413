.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  text-align: center;
}

.confirmation-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.confirmation-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.confirmation-buttons button:first-child {
  background-color: #f0f0f0;
  color: black;
}

.confirmation-buttons .confirm-button {
  background-color: #dc3545;
  color: white;
}

.confirmation-buttons .confirm-button:hover {
  background-color: #c82333;
}

.cancel-button {
  color: black;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #f0f0f0;
} 